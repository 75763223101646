import laurence from "../../assets/img/team/laurence-kirk.jpg";
import tom from "../../assets/img/team/tom-dwyer.jpg";
import karol from "../../assets/img/team/karol-podufalski.jpg";
import wincent from "../../assets/img/team/wincenty-dulkowski.jpg";
import cosimo from "../../assets/img/team/cosimo-de-medici-di-toscana.jpg";
import luke from "../../assets/img/team/luke-wickens.jpg";
import mark from "../../assets/img/team/mark-blunden.jpg";

export const team = [
  {
    name: "Laurence Kirk",
    title: "CEO",
    profileImg: laurence,
    description: `
          After a successful career writing financial software I was captivated by the potential of blockchain
          technology. I set up Extropy.io, a consultancy specialising in blockchain and cryptography. I am a firm
          believer in community education and frequently run blockchain workshops, as well as teach at the University of
          Innsbruck Business Management School and Peking University HSBC Business School.
    `,
    linkedin: "https://www.linkedin.com/in/extropylaurence/",
  },
  {
    name: "Dr Tom Dwyer",
    title: "Blockchain Engineer",
    profileImg: tom,
    description: `
        Prior to Extropy.io I spent a decade in automotive powertrain research and development where I witnessed the importance of reliable software. I was introduced to Bitcoin in 2014 and began wrapping my head around the game-changing potential blockchains offer.
        I joined Extropy.io summer 2019 and now develop revolutionary applications based on decentralised technologies.
    `,
    linkedin: "https://www.linkedin.com/in/tom-dwyer-ph-d-a0884821/",
  },
  {
    name: "Karol Podufalski",
    title: "Blockchain Engineer",
    profileImg: karol,
    description: `
        My passion for blockchain technology evolved during my engineering studies and software graduate programme after which I decided to pursue a career in this industry by joining Extropy.io. This allowed me to work hands on developing innovative ideas that can be implemented in real world.
    `,
    linkedin: "https://www.linkedin.com/in/karolpod/",
  },
  {
    name: "Wincenty Dulkowski",
    title: "Blockchain Engineer",
    profileImg: wincent,
    description: `
        My experience prior to joining Extropy was as an embedded engineer in the automation of medical devices assembly. Immutability of blockchain is what incited me to research this area further, this caveat demands extensive verification so despite it being a software oriented process it is actually akin to hardware design. I have worked at extropy for a year and look forward to further exploring decentralised applications.
    `,
    linkedin: "https://www.linkedin.com/in/wincenty-dulkowski-7b466161/",
  },
  {
    name: "Cosimo De' Medici Di Toscana",
    title: "Blockchain Security Engineer",
    profileImg: cosimo,
    description: `
        Dedicated multilingual IT Security and Blockchain professional. Highly skilled in utilizing cutting edge technologies and security best practices to develop successful technical solutions.
    `,
    linkedin: "https://www.linkedin.com/in/demedicicosimo/",
  },
  {
    name: "Lucke Wickens",
    title: "Blockchain Engineer",
    profileImg: luke,
    description: `
        Prior to joining the Extropy team, I worked in Finance for both a large international investment bank and a Fund Manager/ACD. I have been interested in blockchain/DLT technology since early in the ICO boom of 2017. The potential of blockchain technology and how it can vastly improve traceability of materials and provide banking to the bankless. I now help our clients’ apply blockchain technology to their products.
    `,
    linkedin: "https://www.linkedin.com/in/lukewickens/",
  },
  {
    name: "Dr Mark Blunden",
    title: "Cryptographer",
    profileImg: mark,
    description: `
    A cryptography and key management specialist. Proven and extensive background in the theory and practical application of cryptographic techniques, security research and the design and development of cryptographic applications and protocols. PhD in cryptography and mathematics.
    `,
    linkedin: "https://www.linkedin.com/in/mark-blunden-8769394/",
  },
];
