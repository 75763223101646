import React from "react";

// assets
import encodeClub from "../../assets/img/encode-club.png";

// components
import Section from "../../components/Section";

const Education = () => {
  return (
    <Section id="education">
      <div className="container pt-2 py-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            Learn Blockchain Development with <span>Extropy.io</span> &
            <span style={{ color: "#145BFF" }}> Encode Club </span>
          </h2>
          <div className="section-subtitle mr-auto ml-auto">
            <p>Since summer 2021, we are running Free Courses with Encode Club.</p>
            <p>
              During a 8 weeks courses, you will learn everything you need to get started with Ethereum & Solidity
              Development, including smart contract development & security, web3.js, truffle, hardhat, dApp design, and
              more...
            </p>
          </div>
        </div>

        <div className="section-content">
          {/* Encode Academy */}
          <div className="row text-center justify-content-center">
            <div className="col-md-12 mb-5">
              <a href="https://www.encode.club/" target="_blank" rel="noopener noreferrer">
                <img src={encodeClub} alt="promotion" style={{ width: "75%" }} />
              </a>
              <div class="my-3">
                <a
                  style={{ backgroundColor: "#145BFF" }}
                  className="btn btn-lg text-light"
                  href="https://www.encode.club/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Register
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Education;
