import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// assets
import foundationImage from "../../assets/img/extropy_foundation.jpg";
import arkLogo from "../../assets/img/partners/ark-io-logo.png";
import marketSquareLogo from "../../assets/img/partners/marquetsquare-logo.png";
import barclaysEagleLabLogo from "../../assets/img/partners/barclay-eagle-labs-logo.png";
import benLogo from "../../assets/img/partners/ben-logo.png";
import indorseLogo from "../../assets/img/partners/indorse-logo.png";

// data
import { papers, tutorials, videos } from "./education";

// components
import Section from "../../components/Section";
import YoutubeEmbed from "../../components/YoutubeEmbed";

const Education = () => {
  const [activeTab, setActiveTab] = useState("tutorials");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <Section id="education">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            <span>Explore </span>& Learn
          </h2>
          <p className="section-subtitle mr-auto ml-auto">
            Discover our tutorials and resources to learn how to build smart contracts in Solidity, Ethereum enterprise
            development and Zero-Knowledge proofs.
          </p>
        </div>

        <div className="section-content my-5">
          <Nav pills fill>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "tutorials" })}
                onClick={() => {
                  toggle("tutorials");
                }}
              >
                <h4>Tutorials</h4>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "videos" })}
                onClick={() => {
                  toggle("videos");
                }}
              >
                <h4>Videos</h4>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "papers" })}
                onClick={() => {
                  toggle("papers");
                }}
              >
                <h4>Papers</h4>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="tutorials">
              <div className="row my-5">
                {tutorials.map((tutorial) => (
                  <div className="col-lg-4 mb-3">
                    <div className="card rounded-0">
                      <img src={tutorial.image} className="card-img-top" alt="Blog 1" />
                      <div className="card-body">
                        <h4 className="card-title">{tutorial.title}</h4>
                        <p className="card-text">{tutorial.description}</p>
                        <a href={tutorial.link} className="btn btn-sm btn-primary">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </TabPane>
            <TabPane tabId="videos">
              <div className="row my-5">
                {videos.map((video) => (
                  <div className="col-lg-6">
                    <div className="card rounded-0">
                      <YoutubeEmbed embedId={video.embedId} />
                      <div className="card-body">
                        <h5 className="card-title">{video.title}</h5>
                        <p className="card-text">{video.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </TabPane>
            <TabPane tabId="papers">
              <div className="row my-5">
                {papers.map((paper) => (
                  <div className="col-lg-6 mb-3">
                    <div className="card rounded-0">
                      <div className="card-body">
                        <h4 className="card-title">{paper.title}</h4>
                        <p className="card-text">{paper.abstract.substr(0, 350) + "..."}</p>
                        <a href={paper.link} className="btn btn-sm btn-primary">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </TabPane>
          </TabContent>

          {/* Extropy Foundation */}
          <div className="row my-5">
            <div className="col-md-12 col-lg-6 mb-3">
              <div className="aboutImage">
                <a href="http://extropy.foundation/index.html" target="_blank" rel="noopener noreferrer">
                  <img src={foundationImage} alt="about company" />
                </a>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <h3 className="section-title text-secondary-1">Extropy Foundation</h3>
              <div className="my-4" style={{ fontSize: "1.2rem", color: "#6f6f6f" }}>
                <p>Looking for hands-on blockchain programming tutorials?</p>
                <p>
                  We have created the Extropy foundation website, so you can find all the resources you need in one
                  place.
                </p>
                <p>Discover tutorials around Ethereum, Solidity, Web3.js, Defi or Zero-Knowledge Proofs.</p>
              </div>
              <a
                className="btn btn-secondary-1"
                href="http://extropy.foundation/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Browse More
              </a>
            </div>
          </div>

          <div class="row text-center my-5 py-5">
            <div class="col-md-8">
              <img class="partner-logo" src={arkLogo} alt="ARK logo" />
              <img class="partner-logo" src={marketSquareLogo} alt="Market Square logo" />
              <img class="partner-logo" src={barclaysEagleLabLogo} alt="Barclays Eagle Lab logo" />
              <img class="partner-logo" src={benLogo} alt="Blockchain Education Network logo" />
              <img class="partner-logo" src={indorseLogo} alt="Indorse logo" />
            </div>
            <div class="partners-heading col-md-4">
              <h3 class="text-complement">Mentoring & Hackathons</h3>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Education;
